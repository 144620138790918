.grid-project-card-container{
    padding-left: 20px;
    padding-right: 20px;
    @include media-breakpoint-up(md){
        padding-left: 46px;
        padding-right: 46px;
        margin-top: 28px;
        margin-bottom: 74px;
    }
}

.grid-project-card{
 padding-bottom: 24px;
 padding-top: 24px;
 border-bottom: 1px solid rgba(0, 0, 0, 0.2);

 &--title {
                & a{
                    text-decoration: none;
                    color: $dark-turquoise;
                }
            }

 @include media-breakpoint-up(md){
     
        @include media-breakpoint-up(sm){
            
            &:hover img{
                border-radius: 500px;
            }
            &:hover{
                & a{
                    color: $dark-turquoise;
                    text-decoration: underline;
                }
            }
        }

    padding-left: 24px;
    padding-right: 24px;
    border-bottom: none;
    padding-bottom: 0;
    padding-top: 0;
    margin-top: 48px;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    &:nth-child(3n + 4),&:first-child{
        border-left:none;
    }
    
   
 }
    

    &--image{
        & img{
            border-radius: 0;
            transition:border-radius 0.2s ease-in-out;
            // @include media-breakpoint-up(md){
            //     width: 220px;
            //     height: 180px;
            // }
            // @include media-breakpoint-up(lg){
            //     width: 260px;
            //     height: 240px;
            // }
        }
        }
    

    &--category-container{
        margin-top: 16px;
        margin-bottom: 16px;

    }

    &--company-name{
        margin-left: 10px;
        @extend .date-typography;
    }

    &--tag{
        @extend .tag-typography;
    }

    &--title{
        @extend .article-card-heading-typography;
    }
    &--desc{
        margin-top: 20px;
        @extend .cards-desc-typography;
        
    }

}

