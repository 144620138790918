//content page header
.content-page-header {
    background-color: $dark-turquoise;

    &--breadcrumb {
        flex-shrink: 1;
        margin: 0 20px;

        @include media-breakpoint-up(md) {
            // margin-left: 70px;
            margin: 0;
            padding-top: 40px;
        }

        & svg path {
            stroke: #9EF8EE;
        }
    }

    &--title {
        & h1,p{
            @extend .content-header-title-typography;
        }

        margin-top: 40px;
        padding-left: 20px;
        padding-right: 20px;

        @include media-breakpoint-up(md) {
            padding-left: 0;
            margin-top: 0;
        }

        & h1::after , p::after {
            content: ".";
            color: red;
            font-size: larger;
        }
    }

    &--title-container {
        @include media-breakpoint-up(md) {
            // margin-top: 64px;
        }
    }

    &--btn-title-container {
        width: 100%;

        @include media-breakpoint-up(md) {
            margin-left: 70px;
            width: 60%;
        }

        ;

    }

    &--subtitle {
        @extend .content-header-subtitle-typography;
        color: white;
        margin-top: 10px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 24px;

        @include media-breakpoint-up(md) {
            padding-left: 0;
            padding-bottom: 54px;
        }
    }

    &--img {
        & img {
            width: 100%;
            clip-path: circle(82.5% at 80% -5%);

            @include media-breakpoint-up(md) {
                clip-path: circle(110% at 102% -12%);
                // width: auto;
            }
        }
    }
}