//changing grid breakpoints
$grid-breakpoints: (
  xs: 320px,
  sm: 768px,
  md: 992px,
  lg: 1200px,
);

//changing the gutter width and grid columns for different screen sizes
@include media-breakpoint-up(lg){
    $grid-columns: 12;
    //$grid-gutter-width:48px;
}
@include media-breakpoint-up(md){
    $grid-columns: 12;
    //$grid-gutter-width:34px;
}
@include media-breakpoint-up(sm){
    $grid-columns: 12;
    //$grid-gutter-width:24px;
}
@include media-breakpoint-up(xs){
    //$grid-columns: 12;
    //$grid-gutter-width:20px;
}

//Color Variables
$dark-turquoise: #1C4848;
$mid-turquoise: #318181;
$aa-red: #D81411;
$red: #FE0804;
$bright-turquoise: #22BABB;
$light-bright-turquoise: #9EF8EE;
$pastel-turquoise: #E1F0EC;
$black: #000000;
$nearly-white: #F8F8F8;
