.meet-the-team-block {
    background-color: $mid-turquoise;
    padding: 38px 20px 58px;
    @include media-breakpoint-up(lg) {
        padding-left: 112px;
        padding-right: 184px;
    }

    &--title{
        & h1{
            color: white;
        }
    }
    &--image {
        margin-bottom: 28px;
    }
    &--subtitle {
        margin-top: 14px;
        margin-bottom: 24px;
        @include media-breakpoint-up(lg) {
            margin-top: 18px;
            max-width: 444px;
        }
    }
    &--title-container {
        @include media-breakpoint-up(md) {
            margin-left: 90px;
        }
    }
}
