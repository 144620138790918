.our-people-block {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    margin: 20px 20px 50px;
    padding-top: 16px;
    @include media-breakpoint-up(lg) {
        margin: 48px 70px 64px 70px;
        padding-top: 16px;
    }
    &--title {
        margin-bottom: 24px;
        & h1{
            @extend .our-people-title-typography;
            
             &::after {
            content: ".";
            color: red;
            margin-left: -6px;
            font-size: larger;
        }
        }
    }

    &--subtitle {
        // @extend .our-people-subtitle-typography;
        font-weight: 400;
        font-size: 28px;
        line-height: 36px;

        @include media-breakpoint-up(md) {
            max-width: 750px;
        }
    }
}

.profile {
    margin-top: 22px;
    margin-bottom: 22px;
    padding: 0;

    &--name {
        margin-top: 14px;
        margin-bottom: 6px;
        @extend .profile-grid-title-typography;
    }
    &--job-title {
        @extend .profile-job-title-typography;
    }
}

.profile-container {
    margin: 50px 20px 62px;
}

// our people CTA Panel
.our-people-cta {
     background-repeat: no-repeat;
     background-position: top right;
    border-bottom: 1px solid $pastel-turquoise;
    background-color: $dark-turquoise;
    padding: 46px 20px 62px;
    @include media-breakpoint-up(lg) {
        padding: 60px 70px;
    }
    &--title {
        @extend .our-people-cta-title-typography;
    }
    &--subtitle {
        margin-top: 20px;
        margin-bottom: 32px;
        @extend .our-people-subtitle-typography;
        color: white;

        @include media-breakpoint-up(lg) {
            margin-top: 32px;
            margin-bottom: 32px;
            max-width: 450px;
        }
    }
    &--btn {
        & button {
            @extend .text-btn-typography;
        }
    }
}

.cta-footer-container {
    background-color: $dark-turquoise;
}
.footer-with-cta {
    padding: 26px 20px 52px;
    //border-top: 1px solid $pastel-turquoise;
    @include media-breakpoint-up(lg) {
        padding: 0;
    }
}

//profile details

.profile-details-block {
    margin: 0 20px 64px;
    padding-top: 16px;

    border-top: 1px solid rgba(0, 0, 0, 0.2);
    @include media-breakpoint-up(lg) {
        margin-left: 70px;
        margin-right: 70px;
    }
    &--main-content {
        @include media-breakpoint-up(lg) {
            margin-right: 60px;
        }
    }
    &--name {
         & h1{

             @extend .profile-title-typography;
             &::after {
            content: ".";
            color: red;
            margin-left: -6px;
            font-size: larger;}
             
         }
    }
    &--job-title {
        @extend .profile-jobtitle-typography;
        margin-top: 6px;
    }
    &--main-desc {
        @extend .profile-main-desc-typography;
        margin-bottom: 24px;
        @include media-breakpoint-up(lg) {
            margin-top: 64px;
            margin-bottom: 24px;
        }
    }
    &--sub-desc {
        @extend .profile-sub-desc-typography;
    }
    &--img {
        margin-top: 24px;
        margin-bottom: 38px;
        @include media-breakpoint-up(lg) {
            min-width: 500px;
        }
    }
}

.team-block {
    margin: 30px 0 44px;
    @include media-breakpoint-up(md) {
        margin: 44px 70px 90px;
    }
}
