.main-content {
    & .rich-text {
        margin: 44px 20px;
        @include media-breakpoint-up(md) {
            margin: 64px 170px;
        }

        img {
            width: 100%;
        }
    }
}

.project-details-body {
    & .rich-text {
        margin: 24px 20px;
        @include media-breakpoint-up(md) {
        margin: 24px 170px 0 64px;
        
        }
    }
}
