.text-hero-block-wrapper{
    margin:24px 20px 48px;
     @include media-breakpoint-up(md){
        margin: 44px 70px 60px;
    }
}
.text-hero-block{

     & .divider{
        margin-top: 20px;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        @include media-breakpoint-up(md){
            margin-top: 44px;
        }

    }
    
    &--title{
    padding-top: 16px;
    @include media-breakpoint-up(md){
        width: 70%;
        padding-top: 20px;
    }

   
   
        & h1{
            @extend .cta-title-typography;
            color: black;

            &::after {
            content: ".";
            color: red;
            //margin-left: -4px;
            font-size: larger;
        }
        }
    }
    &--subtitle{
        margin-top: 20px;
        margin-bottom: 48px;
        @include media-breakpoint-up(md){
            width: 70%;
            margin-top: 32px;
            margin-bottom: 56px;
        }

        & h2{
            @extend .content-header-subtitle-typography;
            
        }

    }
}


//404 two card panel link
.error-page-two-card-panel-link{
    margin-top: 24px;
        @include media-breakpoint-up(sm){
            margin-top: 0;
        }
}

.error-page-two-card-panel-heading-container{
    @include media-breakpoint-up(md){
        margin-bottom: 30px;
    }
}