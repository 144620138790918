
//map block
.map-block{
    margin: 32px 20px;
    @include media-breakpoint-up(md){
        margin: 0 100px 84px 70px;
    }
    
    &--img{
        margin-top: 24px;
        margin-bottom: 24px;
    }
    &--text{
        @extend .cards-desc-typography;
    }
    &--title{
        margin-top: 32px;
        @extend .cards-desc-typography;

    }
    &--subtitle{
        @extend .cards-desc-typography;

    }
}

//locations block

.location-block{
    margin-top: 24px;
    margin-bottom:24px;
    @include media-breakpoint-up(sm){
        margin-right: 100px;
    }

   
    &--container{
        margin:24px 20px 48px;

        @include media-breakpoint-up(md){
        border-top:  1px solid rgba(0, 0, 0, 0.2);
        padding-top: 34px;
        margin-left: 70px;
        margin-right: 70px;
        margin-top: 0;
    }
    }
    
    &--location-name{        
        @extend .cards-desc-typography;
    }
    &--key-contact{
        @extend .cards-desc-typography;
    }
    &--email{
        @extend .cards-desc-typography;
        color: $mid-turquoise;
        text-decoration: underline;
    }
}

// pardot-gated-get-in-touch-form

.pardot-gated-get-in-touch-form{
    margin: 32px 20px 48px;
    @include media-breakpoint-up(md){
        width: 70%;
        margin-right: 100px;
    }

    //temparary styling
    height: 500px;
    background-color: rgb(140, 135, 135);
    color:greenyellow;
}