.pagination-block {
    @extend .pagination-btn-typography;
    padding: 40px 0;
    margin: 0;
    & ul,
    li,
    a {
        text-decoration: none;
        list-style: none;
        padding: 0;
        margin: 0;
    }

    //prev btn block
    &--prev-btn {
        padding-right: 18px;

        &:hover &__arrow {
            & svg path {
                fill: #1c4848;
            }
            & a {
                text-decoration: underline;
                text-decoration-thickness: 1px;
            }
        }

        &__disabled {
            color: #a5a5a5;
        }
        &__active {
            &:focus {
                outline: 3px solid $bright-turquoise;
                border-radius: 4px;
            }
            color: #1c4848;
            &:hover {
                color: #1c4848;
            }
        }
    }

    //number block
    &--number-block {
        
        & a {
            color: black;
        }
        & .page-number-active {
            background-color: $bright-turquoise;
            border-radius: 40px;
        }

        & .page-number-item {
            padding-left: 4px;
            padding-right: 4px;
        }
    }
    &--number-item {
        width: 32px;
        height: 32px;
        padding-left: 4px;
        padding-right: 4px;
        &:hover {
            background-color: $dark-turquoise;
            color: white;
            border-radius: 40px;
        }
    }

    //next btn block
    &--next-btn {
        padding-left: 18px;

        &:hover &__arrow {
            & svg path {
                fill: #1c4848;
            }
            & a {
                text-decoration: underline;
                text-decoration-thickness: 1px;
            }
        }

        &__disabled {
            color: #a5a5a5;
        }
        &__active {
            &:focus {
                outline: 3px solid $bright-turquoise;
                border-radius: 4px;
            }
            color: #1c4848;
            &:hover {
                color: #1c4848;
            }
        }
    }
}

//filter block css
.filter-block {
     @include media-breakpoint-up(md) {
         margin: 48px 70px 0 70px;
     }
    margin: 24px 20px;
    padding-top: 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    & a {
        @extend .filter-typography;
        color: #1c4848;
        text-decoration: none;
        margin-left: 21px;
        margin-right: 21px;

        &:first-child {
            margin-left: 0;
        }
        &:hover {
            border-bottom: 3px solid #d81411;
        }
    }

    & .active-filter-element {
        border-bottom: 3px solid #d81411;
    }
}

.filter-dropdown {
    margin: 24px 0 10px;
    padding: 0 20px;
    @include media-breakpoint-up(md) {
        margin: 24px 20px 10px;
        padding: 0 46px;
    }
    
    &--overlay , &--overlay__category{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 10;

}
    &--overlay-content, &--overlay-content__category{
        position: sticky;
        z-index: 11;

}
    
    &--options {
        background-color: inherit;
        width: 300px;
        & h2 {
            color: $dark-turquoise;
            padding: 14px 16px;
            margin: 0;
        }
        cursor: pointer;
        border: 2px solid $dark-turquoise;
        position: relative;
        // height: 44px;
        border-radius: 200px;

        & .option-arrow {
            padding-right: 20px;
        }

        &:focus {
            outline: 3px solid $bright-turquoise;
        }
        &:hover {
            border: 2px solid $mid-turquoise;
        }
    }
    &--content {
        position: sticky;
        z-index: 11;
        background: linear-gradient(
                0deg,
                rgba(255, 255, 255, 0.39),
                rgba(255, 255, 255, 0.39)
            ),
            rgba(255, 255, 255, 0.27);
        backdrop-filter: blur(67.957px);
        position: absolute;
        z-index: 2;
        // height: 122px;
        width: 200px;
        margin-top: -58px;
        border-radius: 14px;
        & a {
            text-decoration: none;
            @extend .cards-desc-typography;
            color: black;
            &:hover {
                color: black;
            }
        }

        //scrollbar styling 
        // &::-webkit-scrollbar {
        //   width: 20px;
        // }

        // /* Track */
        // &::-webkit-scrollbar-track {
        //   box-shadow: inset 0 0 5px grey; 
        //   border-radius: 10px;
        // }
        
        // /* Handle */
        // &::-webkit-scrollbar-thumb {
        //    background: #8e8e8e; 
        //   border-radius: 10px;
        // }
    }
    &--item {
        @extend .cards-desc-typography;
        padding: 8px 32px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }

    & .check-mark-icon{
        margin-left: -16px;
    }


}
