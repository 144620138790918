.gateway-header {
    background-color: $mid-turquoise;

    &--breadcrumb{
        & svg path{
            stroke:#9EF8EE ;
        }
        
    }
    &--title{
        margin-top: 50px;
        & h1 , p{
            @extend .gateway-header-title-typography;
        }
        @include media-breakpoint-up(md){ 
            margin-bottom: 54px;
            // margin-top: 70;
        }
        
        & h1::after , p::after{
                content:".";
                color: red;
                margin-left: 2px;
                font-size:larger;
            }
    }
   

    &--title-container{
        margin: 20px 20px;
        @include media-breakpoint-up(md) {
            padding-left: 70px;
            width: 80%;
            margin-left: 0;
            margin-top: 40px;
        }
    }


    &--img {
        & img {
            clip-path: circle(77.2% at 51% 100%);
            @include media-breakpoint-up(md) {
            clip-path: circle(85% at 80% 50%);
                
            }
        }
    }

}

.split-panel {
    margin-top: 32px;
    margin-bottom: 32px;
    @include media-breakpoint-up(lg) {
        margin: 64px 70px;
    }
    &--title {
        @extend .split-panel-title-typography;
        padding-left: 20px;
        padding-right: 20px;
        @include media-breakpoint-up(lg) {
            padding-left: 48px;
            padding-right: 48px;
            max-width: 550px;
        }
    }
    &--img {
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 32px;

        @include media-breakpoint-up(lg) {
            padding: 0;
        }

        & img {
            border-radius: 50%;
        }
    }
}

//working at posterscope
//rich text
.rich-text-block {
    margin: 46px 20px;
    @include media-breakpoint-up(lg) {
        margin: 64px 172px;
    }
}

//large text block
.large-text-block {
    margin-top: 52px;
    margin-bottom: 48px;
    @include media-breakpoint-up(lg) {
        margin-top: 60px;
        margin-bottom: 78px;
    }

    &--content {   
            @extend .large-text-panel-typography;
        padding: 44px 20px;
        @include media-breakpoint-up(lg) {
            padding: 74px 170px 58px;
        }
    }
}

//video block

.video-block {
    &--content {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;
        & .embeded__video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    &--caption {
        @extend .video-caption-typography;
        margin-left: 20px;
        margin-top: 10px;
        @include media-breakpoint-up(lg) {
            margin-left: 0;
            margin-top: 20px;
        }
    }
}

.main-content{
    & .video-block{
    margin-top: 44px;
    margin-bottom: 48px;
    @include media-breakpoint-up(lg) {
        margin: 64px 172px 60px;
    }
    }
}

.project-details-body{
    & .video-block{
    margin-top: 24px;
    margin-bottom: 24px;
    @include media-breakpoint-up(md) {
        padding: 0;
        margin-right: 170px;
        margin-left: -120px;
    }
    }
}

// .gateway-header--img{
//     background-position: center;
//         background-repeat: no-repeat;
//         background-size: cover;
//         position: relative;
// }
