
.breadcrumb {
    max-width: 250px;
    @include media-breakpoint-up(lg){
        max-width: 500px;
    }

    & a, span{

        white-space: nowrap;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        &:last-child{
            text-decoration: none;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        } 
    }

    & a:hover {
        text-decoration: underline;
    }
    
}

.gateway-pages-breadcrumb{
    margin: 20px;
    // margin-bottom: 0;
    @include media-breakpoint-up(lg) {
    margin: 44px 70px 44px 70px;
    }
    & a,span{
        color: $mid-turquoise;
    }
}

.breadcrumb-light{
    & a, span{color: $light-bright-turquoise;}
}
.breadcrumb-dark{
    & a, span{color: $mid-turquoise;}
}

