.btn-light {
    & button{
        @extend .cta-btn-typography;
        padding: 10px 26px;
        background-color: $light-bright-turquoise;
        border-radius: 100px;
        border: none;
        color:$dark-turquoise;
        transition: background-color ease-out 0.2s;
    }
    &:hover button{
        background-color:$mid-turquoise;
        color: white;
    }
    &:focus button{
        outline: $bright-turquoise 3px solid;
    }
}

.btn-dark {
    & button{
        @extend .cta-btn-typography;
        padding: 10px 26px;
        background-color: $dark-turquoise;
        border-radius: 100px;
        border: none;
        color:white;
        transition: background-color ease-out 0.2s;
    }
    &:hover button{
        background-color:$mid-turquoise ;
    }
    &:focus button{
        outline: $bright-turquoise 3px solid;
    }
}


.text-btn {
    @extend .text-btn-typography;
    padding: 1px 0;
    text-decoration: none;
    color: $dark-turquoise;
    transition: all ease-out 0.2s;

    &:hover{
        color: $dark-turquoise;
        text-decoration: underline;
        svg{
            circle {
                fill: #1C4848;
            }
        }
    }
    &:focus{
        outline: $bright-turquoise 3px solid;
        border-radius: 4px;
    }
}

.text-btn-large{
    border-top:1px solid rgba(0, 0, 0, 0.2);
    & a{

        @extend .text-btn-lg-typography;
    }

        padding:24px 0 24px;
        margin: 0 20px;
    @include media-breakpoint-up(lg){
        padding:32px 0 64px;
        margin: 0 70px;
    }
}

.tag {
    padding: 3px 12px;
    background-color: $aa-red;
    border-radius: 20px;
}


