@import "../variables";

.nav-bg-graphic{
    background: url("../../assets/images/nav-bg-graphic.svg") no-repeat;
    background-size: cover;
}
.nav-item-container {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 24px;
    padding-bottom: 24px;
    @include media-breakpoint-up(lg) {
        padding-left: 70px;
        padding-right: 70px;
        padding-top: 28px;
        padding-bottom: 28px;
    }
    border-bottom: 1px solid rgba(255, 255, 255, 0.27);

    & .header-nav-item{
        
        padding-top: 48px;
        padding-bottom: 48px;
        @include media-breakpoint-up(md) {
            padding: 0;
        }
    }

    & .search-input-container {
        margin-top: 48px;
        margin-bottom: 48px;
        @include media-breakpoint-up(md) {
            margin: 0;
        }

        input {
            color: white;
            background: url("../../assets/images/search-icon.svg")
                no-repeat;
            background-position: 96% 50%;

            &::placeholder {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 120%;
                letter-spacing: -0.5px;
                color: white;
                opacity: 0.7;
                text-transform: capitalize;
            }
        }

        input[type="text"] {
            @include media-breakpoint-up(md) {
                width: 50vw;
            }
            width: 100%;
            padding-right: 50px;
            box-sizing: border-box;
        }

        & .search-input {
            background-color: inherit;
            width: 100%;
            padding: 10px 22px;
            border: 2px solid white;
            border-radius: 30px;
            @include media-breakpoint-up(md) {
                padding: 6px 22px;
            }
            outline: none;
        }

        & .search-input-btn {
            right: 20px;
            top: 4px;
            width: 34px;
            height: 36px;
            border: none;
            background-color: inherit;
        }
    }

    @include media-breakpoint-up(md) {
        margin-left: 0;
        margin-right: 0;
    }

    & .get-in-touch-btn {
        @include media-breakpoint-up(md) {
            margin-left: 74px;
        }
    }

    & .nav-item {

        text-decoration: none;
        display: inline;
        margin-bottom: 40px;
        // border-bottom: 3px transparent solid;
        
        

        &:hover {
            // border-bottom: 3px red solid;
            text-decoration: underline;
            text-decoration-color: red;
            text-underline-offset: 8px;
            text-decoration-thickness: 2px;
            
        }

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            margin-left: 42px;
        }
    }
    & .active-nav-item {
        @include media-breakpoint-up(md){
            text-decoration: underline;
            text-decoration-color: red;
            text-underline-offset: 8px;
            text-decoration-thickness: 2px;
        }
    }
}



.footer-nav-item {
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
    @include media-breakpoint-up(md) {
        margin-left: 42px;
        // margin-bottom: 30px;
        // margin-top: 30px;
    }
}

.footer {
    background-color: $dark-turquoise;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 20px;
    padding-right: 20px;
}

.copyright-container {
    color: white;
    font-size: 14px;
}

.bg-circle {
    background-image: url("../../assets/images/bg-graphic-circle.png");
    background-repeat: no-repeat;
   
    background-position: 100% 10%;
    background-size: 80vw;
    @include media-breakpoint-up(md) {
        background-size: 80vw;
        background-position: right top;  
    }
}
.bg-arc {
    background-image: url("../../assets/images/bg-graphics-arc.png");
    background-repeat: no-repeat;
   
    background-position: 92% 19%;
    background-size: 150vw;
  
    @include media-breakpoint-up(md) {
        background-size: 52vw;
        background-position: left 35%;
    }
}

.searchIconSvg {
  
    &:hover {
       & circle {
                stroke: #318181;
            }
        fill: #318181;

    }
    &:focus {
        & circle {
                stroke: $dark-turquoise;
            }
        outline: 2px solid $bright-turquoise;
        border-radius: 20px;
    }
}

//overlay testing
body {
    position: relative;
}
.nav-item-overlay-container {
    background-color: $dark-turquoise;
     width: 100%;
    //  height: 100vh;
        position: sticky;
        // position: fixed;
        // top: 0;
        // left: 0;
        z-index: 11;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.6);
}

.brand-logo{
    background-image: url("../../assets/images/brand-logo.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 132px;
    height: 23px;
    @include media-breakpoint-up(lg){
        width: 181px;
        height: 32px;
    }
    
}