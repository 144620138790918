.search-page {
    &--input-container {
        background-color: $dark-turquoise;
        padding: 24px 20px;
        @include media-breakpoint-up(md) {
            padding: 40px 74px;
        }
    }
    &--input {
        padding: 24px 0;
        @include media-breakpoint-up(md) {
            padding: 40px 100px 8px;
        }

        @include media-breakpoint-up(md) {
            margin: 0;
            // min-width: 600px;
            // width: 560px;
        }

        input {
            outline: none;
            color: white;
            width: 100%;
            background: url("../../assets/images/search-icon.svg")
                no-repeat;
            background-position: 96% 50%;
            @include media-breakpoint-up(lg){
                background-position: 98% 50%;

            };

            &::placeholder {
                //font-family: 'Avenir Next';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 120%;
                letter-spacing: -0.5px;
                color: white;
                opacity: 0.7;
                text-transform: capitalize;
            }
        }

        input[type="text"] {
            width: 100%;
            padding-right: 50px;
            // margin: 8px 0px;
            box-sizing: border-box;
        }

        & .search-input {
            background-color: inherit;
            width: 100%;
            padding: 10px 22px;
            border: 2px solid white;
            border-radius: 30px;
            @include media-breakpoint-up(md) {
                padding: 6px 22px;
            }
        }

        & .search-input-btn {
            right: 11px;
            top: 4px;
            width: 36px;
            height: 36px;
            border: none;
            background-color: inherit;
        }
    }

    &--header {
        padding-bottom: 8px;
        @include media-breakpoint-up(md) {
            padding-bottom: 48px;
        }
        & h3 {
            @extend .search-page-heading-typography;
        }
    }

    &--results {
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        padding-top: 16px;
        padding: 44px 20px;
        @include media-breakpoint-up(md) {
            margin: 48px 170px 0;
            padding-bottom: 48px;
            
        }
    }

    &--item {
        margin-top: 26px;
        padding-bottom: 36px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        @include media-breakpoint-up(md) {
            margin-top: 20px;
            padding-bottom: 28px;
        }
        &__news {
            font-weight: 400;
            font-size: 14px;
            color: white;
        }
        &__date {
            font-weight: 400;
            font-size: 14px;
            margin-left: 10px;
        }
        &__heading {
            margin-top: 24px;
            margin-bottom: 24px;
            & a{
                text-decoration: none;
            }
            & h3 {
                @extend .search-page-heading-typography;
                color: $mid-turquoise;
            }
        }
        &__desc {
            & p {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }

    &--breadcrumb {
     
            @extend .breadcrumb-light;
       
        & svg path {
            stroke: $light-bright-turquoise;
        }

    }
}

//search-page-pagination

.serach-page-pagination {
    button {
        border: none;
        background-color: inherit;
    }
    margin-top: 50px;
    margin-bottom: 8px;
    &--prev-btn {
        padding-right: 14px;
        @include media-breakpoint-up(md) {
            padding-right: 24px;
        }

        &:hover  {
            & .search-page-active-btn svg path {
                fill: #1c4848;
            }

            text-decoration: underline;
            text-decoration-thickness: 1px;
        }
    }
    &--number-block {
        //number block

        color: black;

        & .page-number-active {
            background-color: $bright-turquoise;
            border-radius: 40px;
        }

        & .number-item {
            width: 32px;
            height: 32px;
            padding-left: 4px;
            padding-right: 4px;
            &:hover {
                background-color: $dark-turquoise;
                color: white;
                border-radius: 40px;
            }
        }
    }

    &--next-btn {
        padding-left: 14px;
        @include media-breakpoint-up(md) {
            padding-left: 24px;
        }

        &:hover {
            & .search-page-active-btn svg path {
                fill: #1c4848;
            }

            text-decoration: underline;
            text-decoration-thickness: 1px;
        }
    }
}
