//@import url("https://fonts.googleapis.com/css2?family=Mulish&display=swap");
body {
    font-family: "avenir-next";
    color: black;
}

.nav-item-typography {
    //font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 120%;
    letter-spacing: -1.13934px;
    color: white;

    @include media-breakpoint-up(md) {
        font-size: 18px;
        font-weight: 500;
        //line-height: 23px;
        letter-spacing: 0;
        text-align: right;
    }

    &:hover {
        color: white;
    }
}

.footer-nav-typography {
    //font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: $light-bright-turquoise;
    &:hover {
        color: $light-bright-turquoise;
    }
}

.btn-typography {
    //font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    text-align: right;
    color: $dark-turquoise;
}

//homepage header typography

.homepage-header-typography {
    //font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 120%;
    letter-spacing: -1.13934px;
    color: #ffffff;

    @include media-breakpoint-up(lg) {
        font-weight: 500;
        font-size: 52px;
        line-height: 62px;
        letter-spacing: -1px;
    }
}

//our work typography

.our-work-title-typography {
    //font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 34px;
    @include media-breakpoint-up(lg) {
        font-size: 40px;
        line-height: 48px;
        letter-spacing: -0.2px;
    }
}
.cards-desc-typography {
    //font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.text-btn-typography {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.5px;
    color: $dark-turquoise;
}
.text-btn-lg-typography{
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 100%;
    letter-spacing: -1px;
    @include media-breakpoint-up(lg){
    font-size: 42px;      
}
}

.project-card-title-typography {
    //font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    @include media-breakpoint-up(lg) {
        font-size: 26px;
        line-height: 32px;
    }
}

//what we think typography
.what-we-think-heading-typography {
    //font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 130%;
    letter-spacing: -0.2px;
    color: black;
}

.tag-typography {
    //font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 110%;
    color: white;
}

.article-card-heading-typography {
    //font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 116%;
    color: black;
    @include media-breakpoint-up(lg) {
        font-size: 22px;
        line-height: 24px;
    }
}

.date-typography {
    //font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 110%;

    @include media-breakpoint-up(lg) {
        font-size: 14px;
    }
}

.cta-title-typography {
    //font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -1px;
    // color: white;
    @include media-breakpoint-up(lg) {
        font-size: 52px;
        line-height: 62px;
    }
}

.cta-subtitle-typography {
    //font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
}
.cta-btn-typography {
    //font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    @include media-breakpoint-up(lg) {
        font-weight: 500;
        font-size: 18px;
    }
    & a {
        color: white;

        :hover {
            color: white;
        }
    }
}
.our-people-title-typography {
    //font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 120%;
    letter-spacing: -1px;
    @include media-breakpoint-up(lg) {
        font-size: 52px;
    }
}

.our-people-subtitle-typography {
    //font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    letter-spacing: -1px;
    @include media-breakpoint-up(lg) {
        font-size: 28px;
        line-height: 36px;
    }
}

.profile-grid-title-typography {
    //font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 110%;
    & a {
        color: black;
        text-decoration: none;
        &:hover {
            color: black;
        }
    }
    @include media-breakpoint-up(md) {
        font-size: 26px;
        line-height: 36px;
    }
}
.profile-job-title-typography {
    //font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
}

.our-people-cta-title-typography {
    //font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 100%;
    letter-spacing: -0.5px;
    color: $pastel-turquoise;

    @include media-breakpoint-up(lg) {
        font-size: 80px;
        line-height: 100%;
        letter-spacing: -4px;
    }
}
.our-people-subtitle-typography {
    //font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    @include media-breakpoint-up(lg) {
        font-size: 18px;
    }
}

//gateway header

.gateway-header-title-typography {
    //font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 120%;
    letter-spacing: -1.13934px;
    color: white;
    @include media-breakpoint-up(lg) {
        font-weight: 500;
        font-size: 52px;
        line-height: 62px;
    }
}

.split-panel-title-typography {
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 34px;
    letter-spacing: -0.2px;
    @include media-breakpoint-up(lg) {
        font-size: 40px;
        line-height: 48px;
    }
}

//profile details
.profile-title-typography {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 120%;
    letter-spacing: -1px;
    @include media-breakpoint-up(lg) {
        font-size: 52px;
    }
}
.profile-jobtitle-typography {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    @include media-breakpoint-up(lg) {
        font-size: 28px;
        line-height: 120%;
    }
}

.profile-main-desc-typography {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    @include media-breakpoint-up(lg) {
        font-size: 20px;
        line-height: 30px;
    }
}
.profile-sub-desc-typography {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    @include media-breakpoint-up(lg) {
        font-size: 18px;
        line-height: 26px;
    }
}

//video block
.video-caption-typography {
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
}

//content page header

.content-header-title-typography {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 120%;
    color: white;
    @include media-breakpoint-up(lg) {
        font-weight: 500;
        font-size: 52px;
        line-height: 120%;
    }
}

.content-header-subtitle-typography {
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    @include media-breakpoint-up(lg) {
        font-size: 28px;
        line-height: 120%;
    }
}

//search page typography
.search-page-heading-typography{
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    @include media-breakpoint-up(lg) {
        font-size: 26px;
        line-height: 32px;
    }
}

.pagination-btn-typography{
// font-family: 'gotham-bold';
font-style: normal;
font-weight: 400;
font-size: 16px; //12px on actual font
line-height: 130%;
letter-spacing: 1px;
text-transform: uppercase;
}

.filter-typography{
font-style: normal;
font-weight: 600;
font-size: 32px;
line-height: 130%;
letter-spacing: -0.2px;
}

.large-text-panel-typography{
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 40px;
        letter-spacing: -0.2px;
        color: $dark-turquoise;
        @include media-breakpoint-up(lg){
            font-weight: 500;
            font-size: 40px;
            line-height: 48px;
            text-align: center;  
        }
}
//------------------typography--------------------------------------------------

.font-w-400 {
    font-weight: 400;
}

.font-w-500 {
    font-weight: 500;
}
.font-w-600 {
    font-weight: 600;
}

.brand-h1 {
    font-style: normal;

    font-size: 52px;
    line-height: 62px;
}

.brand-h2 {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.2px;
}

.brand-h2-v1 {
    font-size: 80px;
    line-height: 86px;
    letter-spacing: -2px;
}

.brand-h3 {
    font-size: 26px;
    line-height: 32px;
}

.brand-h4 {
    font-size: 22px;
    line-height: 24px;
}

.brand-h5 {
    font-size: 18px;
    line-height: 22px;
}

.brand-md-h1 {
    font-size: 40px;
    line-height: 52px;
}

.brand-md-h2 {
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.2px;
}

.brand-md-h2-v1 {
    font-size: 60px;
    line-height: 66px;
    letter-spacing: -2px;
}

.brand-sm-h1 {
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -1px;
}

.brand-sm-h1-v1 {
    font-size: 26px;
    line-height: 34px;
    letter-spacing: -0.2px;
}

.brand-sm-h2 {
    font-size: 42px;
    line-height: 100%;
}

.brand-sm-h2-v1 {
    font-size: 20px;
    line-height: 24px;
}
.brand-sm-h3 {
    font-size: 18px;
    line-height: 24px;
}
.brand-sm-h4 {
    font-size: 18px;
    line-height: 120%;
}
.brand-sm-h5 {
    font-size: 16px;
    line-height: 120%;
}

.body-intro-hero {
    font-size: 28px;
    line-height: 36px;
}

.body-intro-article {
    font-size: 20px;
    line-height: 30px;
}

.body-1 {
    font-size: 18px;
    line-height: 26px;
}
.body-2 {
    font-size: 16px;
    line-height: 24px;
}

.body-3 {
    font-size: 16px;
    line-height: 24px;
}
.body-4 {
    font-size: 14px;
    line-height: 20px;
}
.body-5 {
    font-size: 10px;
    line-height: 16px;
}

.body-6 {
    font-size: 32px;
    line-height: 130%;
}

.body-7 {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.5px;
}

.body-8 {
    font-size: 18px;
    line-height: 24px;
}

.body-9 {
    font-size: 28px;
    line-height: 36px;
}
