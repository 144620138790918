.list-panel-block{
    margin: 48px 20px;
    @include media-breakpoint-up(md){
        margin: 96px 170px 60px;
    }

    &--title{
       & h1{
            @extend .our-work-title-typography;
        }
    }
    &--subtitle{
        margin-top: 32px;
        margin-bottom: 32px;

        @include media-breakpoint-up(md){
        margin-top: 44px;
        margin-bottom: 44px;
        }

        & h2{
            @extend .cta-subtitle-typography ;
        }
        
        & a{
            color: $mid-turquoise;
            &::after{
                content:url("../../assets/images/external-link-icon.svg");
                margin-bottom: -4px;
                margin-left: 4px;
            }
        }
    }
}

//category header block

.list-panel-category-header{
    margin-top: 32px;
    margin-bottom: 32px;

    @include media-breakpoint-up(md){
        margin-top: 44px;
        margin-bottom: 44px;
        }

    & h1{
       @extend .our-work-title-typography;
    }
}

//link item
.link-item-block{
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    &--title{
        margin-top: 32px;
        margin-bottom: 24px;
        @include media-breakpoint-up(md){
        margin-top: 32px;
        margin-bottom: 24px;
        }
        & a{
            @extend .project-card-title-typography;
            color: $mid-turquoise;
            text-decoration: none;
        }
    }

    &--desc{
        margin-top: 24px;
        padding-bottom: 20px;
        & p{
            @extend .brand-sm-h5;
        }
    }

    &--external-link{
        & svg{
        margin-bottom: 6px;
        margin-left: 10px;
        @include media-breakpoint-up(md){
            margin-bottom: 10px;
            margin-left: 14px;
        }
        }

    }
    & .link-block-without-desc{padding-bottom: 12px;}
}