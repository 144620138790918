.project-details-header {
    background-color: $dark-turquoise;

    &--image {
        & img {
            // clip-path: circle(82.5% at 80% -5%);
            clip-path: circle(73.5% at 52% -3%);

            @include media-breakpoint-up(md) {
                clip-path: circle(87% at 81% 52%);
            }
        }
    }

    &--image-article {
        & img {
            clip-path: circle(82.5% at 80% -5%);            

            @include media-breakpoint-up(md) {
                clip-path: circle(87% at 81% 52%);
            }
        }
    }

    &--breadcrumb{
        & svg path{
            stroke:$light-bright-turquoise;
        }

        @include media-breakpoint-up (md){
            margin-top: 40px;
        }
    }

    &--title-container {
        padding: 10px 20px 20px;
        width: 100%;

        @include media-breakpoint-up(lg){
            padding-left: 70px;
            margin: 0 0 50px;
            width: 60%;
        }
    }

    &--tag {
        margin-top: 22px;
        margin-bottom: 12px;

        @include media-breakpoint-up(lg){
            margin-top: 54px;
            margin-bottom: 28px;
        }
    }

    &--title {
        & h1 {
            @extend .homepage-header-typography;
            &::after {
                content: ".";
                color: red;
                margin-left: -5px;
            }
        }
    }
}

.project-details-social-icon-wrapper {
    background: url("../../assets/images/bg-arc-article-details.png");
    background-position: top left;
    background-size: contain;
    background-repeat: no-repeat;

    @include media-breakpoint-up(md){
        width: 500px;
        height: 400px;
        padding: 60px 70px;
        margin-top: 0;
    }
    padding: 40px 20px 30px;

    &--date {
        margin-top: 8px;
        font-size: 14px;
        font-weight: 400;
        @include media-breakpoint-up(lg){
            font-size: 18px;
        }
    }

    &--date-container {
        padding-top: 8px;
        padding-bottom: 24px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        border-top: 1px solid rgba(0, 0, 0, 0.2);
    }

    & .project-detail-social-icons-links {
        &--linkedin {
            margin-right: 12px;
        }

        &--twitter {
            margin-right: 12px;
        }

        &--page-link{
            cursor:copy;
        }
    }

    &--published{
        font-size: 14px;
        font-weight: 600;
        @include media-breakpoint-up(lg){
            font-size: 18px;
        };
    }

    &--share{
        font-size: 14px;
        font-weight: 600;

        @include media-breakpoint-up(lg){
            font-size: 18px;
        };
    }
}

.image-block{
    margin: 20px 0;
}

.image-normal-width {
    padding-left: 20px;
    padding-right: 20px;
    @include media-breakpoint-up(md){        
        padding: 0;
        margin-right: 170px;
        margin-left: 64px;
    }

    & .image-block--caption {
        @extend .body-4;
        font-style: italic;
        margin-top: 8px;

        @include media-breakpoint-up(md){
        padding: 0;
        margin-top: 18px;
        }
    }
}

.image-full-width {
    @include media-breakpoint-up(md){        
        padding: 0;
        margin-right: 170px;
        margin-left: -120px;
    }

    & .image-block--caption {
        @extend .body-4;
        font-style: italic;
        margin-top: 8px;
        padding-left: 20px;

        @include media-breakpoint-up(md){
            padding: 0;
            margin-top: 18px;
        }
    }    
}


.project-details-rich-text-wrapper{
    margin-top: 28px;
    margin-bottom: 28px;
    @include media-breakpoint-up(md){
         margin-top: 60px;
         margin-bottom: 96px;
    }
}

.quote-block{
    padding:24px 20px;
    @include media-breakpoint-up(md){        
        margin: 32px 170px 32px 64px;
        padding: 0;
    }

    &--body{
        font-weight: 600;
        font-size: 28px;
        line-height: 36px;
        color: $mid-turquoise;
        @include media-breakpoint-up(lg){
            font-size: 32px;
        }

    }
    &--author-name{
        margin-top: 12px;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
    }
    &--author-position{
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
    }
}

.pardot-gated-content {
    margin: 24px 0;
    @include media-breakpoint-up(md){
        margin: 0 170px 0 48px;
    }
}