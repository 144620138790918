// homepage header

.homepage-header {
    background-color: $dark-turquoise;
    min-height: 37.5vw;

    &--title-container {
        padding: 30px 20px;
        padding-bottom: 0;
        @include media-breakpoint-up(md) {
            // padding: 164px 0 192px 70px;
            padding-left: 70px;
        }
    }

    &--button {
        margin-top: 54px;
    }

    &--title {
        & h1 , p{
            @extend .homepage-header-typography;
        }

        & h1::after ,p::after{
            content: ".";
            color: red;
            //margin-left: -4px;
            font-size: larger;
        }
    }

    &--image {
        min-width: 50vw;
    
        img {
            clip-path: circle(76% at 71% 100%);
            @include media-breakpoint-up(md) {
                clip-path: circle(106.8% at 100% 103%);
            }
        }
    }

    &--img {
        background-position: center;
        background-repeat: no-repeat;
        width: 500px;
        height: 500px;
    }
}
// our work block
.our-work-block {
    margin: 40px 20px 60px;
    padding-top: 14px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    @include media-breakpoint-up(md) {
        padding-top: 16px;
        margin: 65px 70px 70px 70px;
    }

    &--title {
        & a {
            color: black;
            &::after {
                content: ".";
                color: red;
                margin-left: -6px;
            }
        }
    }

    &--desc {
        margin-top: 20px;
        margin-bottom: 20px;
        @include media-breakpoint-up(lg) {
            margin-top: 22px;
            margin-bottom: 0px;
            width: 500px;
        }
    }

    & .project-card {
        margin-top: 28px;
        padding-bottom: 24px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);

        &--title{
            color: black;
            &:hover{
                color:$dark-turquoise;
            }
        }

        & a {
            text-decoration: none;
        }

        @include media-breakpoint-up(sm) {
            &:hover img {
                border-radius: 500px;
            }
            &:hover {
                & a {
                    color: $dark-turquoise;
                    text-decoration: underline;
                }
            }

            & a:focus img {
                border-radius: 500px;
            }
            & a:focus {
                color: $dark-turquoise;
                text-decoration: underline;
            }
        }

        @include media-breakpoint-up(sm) {
            border-right: 1px solid rgba(0, 0, 0, 0.2);
            border-bottom: none;
            padding-bottom: 0;
            padding-right: 25px;
            &:last-child {
                border: none;
                padding-right: 0;
                padding-left: 25px;
            }
        }
        &:last-child {
            border-bottom: none;
        }
        &--image {
            margin-bottom: 20px;

            & img {
                border-radius: 0;
                transition: border-radius 0.2s ease-in-out;
                @include media-breakpoint-up(md) {
                    //height: 300px;
                }
                @include media-breakpoint-up(lg) {
                    //height: 370px;
                }
            }
        }
    }
}

// what we think block

.what-we-think-block {
    margin: 16px 20px 44px;
    padding-top: 14px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    @include media-breakpoint-up(md) {
        padding-top: 16px;
        margin: 65px 70px 70px 70px;
    }
    &--title {
        & a {
            color: black;
            &::after {
                content: ".";
                color: red;
                margin-left: -4px;
            }
        }
    }

    &--desc {
        margin: 16px auto 26px;

        @include media-breakpoint-up(lg) {
            margin: 22px 0 0;
            width: 500px;
        }
    }
    & .article-card-container {
        // padding-left: 20px;
        // padding-right: 20px;
        @include media-breakpoint-up(md) {
            margin-left: -25px;
            margin-right: -25px;
        }
    }
    & .article-card {
        margin-top: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        &:last-child {
            border-bottom: none;
        }

        &--title {
            & a {
                text-decoration: none;
            }
        }

        
            &:hover img {
                border-radius: 500px;
            }
            &:hover {
                & a {
                    color: $dark-turquoise;
                    text-decoration: underline;
                }
            }
        

        &--image {
            & img {
                border-radius: 0;
                transition: border-radius 0.2s ease-in-out;
                // @include media-breakpoint-up(sm) {
                //     width: 220px;
                //     height: 180px;
                // }
                // @include media-breakpoint-up(lg) {
                //     width: 260px;
                //     height: 240px;
                // }
            }
        }

        @include media-breakpoint-up(sm) {
            border-right: 1px solid rgba(0, 0, 0, 0.2);
            border-bottom: none;
            padding-bottom: 0;
            padding-right: 25px;
            padding-left: 25px;

            &:last-child {
                border: none;
                padding-left: 25px;
            }
        }

        &--date-container {
            margin: 18px auto;
        }
        &--tag {
            margin-right: 9px;
        }
    }
}

.search-icon {
    background-color: transparent;
    border: none;
}
